import React, { useState } from "react";
import Header from "../../components/Header";
import {useRecoilValue} from "recoil";
import {fetchAPIResponseSelector} from "../../store/user.atom";
import {toast} from "react-toastify";

const Notifications: React.FC = () => { 

    const [title, setTitle] = useState<any>();
    const [description, setDescription] = useState<any>();

    const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

    const handleSendNotications = async () => {
        try {
            let fetchUrl = `/admins/notification`;

            const response = await fetchAPIResponse(fetchUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    title,
                    message: description
                })
            });

            if(response.code === 200) {
                toast.success("Successfully sent notification")
            } else {
                toast.error("Error whilst sending notification")
            }
        } catch (error) {
            toast.error("Error whilst sending notification: " + error)
        }
    }

    return ( 
        <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
            <Header title={'Notifications'} button={false} buttonTitle={''} action={''} />
            <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 pr-12 mt-6 font-poppins">
                <div className="mt-8 mb-8">
                    <h2 className="text-lg font-semibold text-mainTextColor">Send Notifications</h2>
                    <div className="mt-4">
                        <input
                            type="text"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
                        />
                        <input
                            type="text"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
                        />
                        <button onClick={handleSendNotications} className="bg-[#1490C2] text-white rounded-md p-2 mt-5">
                            Send Notifications
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications;