import React, { useState } from 'react';
import HeaderWithBack from '../../components/HeaderWithBack';
import { useNavigate } from 'react-router';
import {toast} from "react-toastify";
import {CategoryDTO} from "../../dtos/category.dto";
import {useRecoilValue} from "recoil";
import {fetchAPIResponseSelector} from "../../store/user.atom";
import {IngredientDTO} from "../../dtos/ingredient.dto";

type Props = {};

const AddIngredient: React.FC<Props> = () => {
  const navigate = useNavigate();

  const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);
  const [ingredientName, setIngredientName] = useState<string>('');
  const [ingredientPrice, setIngredientPrice] = useState<string>('');

  const handleAddIngredient = async () => {
    if(ingredientName === '' || ingredientPrice === '') {
      toast.error("Fill in contents before creating the ingredient")
      return;
    }

    try {
      await fetchAPIResponse<IngredientDTO>("/ingredients", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: ingredientName,
          base_price: Number(ingredientPrice)
        })
      });

      navigate('/ingredients')
    } catch (e) {
      toast.error((e as Error).message)
    }
  };

  return (
    <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
      <HeaderWithBack previousPage={'Ingredients'} currentPage={'Add Ingredient'} previousPageUrl={'/ingredients'} />
      <div className='bg-WhiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
        <input
          type='text'
          placeholder='Ingredient Name'
          value={ingredientName}
          onChange={(e) => setIngredientName(e.target.value)}
          className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md'
        />
        <input
          type='text'
          placeholder='Price'
          value={ingredientPrice}
          onChange={(e) => setIngredientPrice(e.target.value)}
          className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md'
        />
        <button onClick={handleAddIngredient} className='bg-[#1490C2] text-white rounded-md p-2 mt-4 w-full'>
          Add Ingredient
        </button>
      </div>
    </div>
  );
};

export default AddIngredient;
