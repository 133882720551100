import React, {useEffect, useState} from 'react'
import Header from '../../components/Header'
import Search from '../../components/Search'
import {CategoryDTO} from "../../dtos/category.dto";
import {useNavigate} from "react-router";
import {useRecoilValue} from "recoil";
import {fetchAPIResponseSelector} from "../../store/user.atom";
import {toast} from "react-toastify";
import {IngredientDTO} from "../../dtos/ingredient.dto";

type Props = {}

const Ingredients = (props: Props) => {
    const [search, setSearch] = useState('');
    const [ingredients, setIngredients] = useState<IngredientDTO[]>([]);
    const navigate = useNavigate();
    const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

    const fetchIngredients = async () => {
        const ingredients = await fetchAPIResponse<IngredientDTO[]>(`/ingredients?search=${search}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        setIngredients(ingredients.data);
    }

    useEffect(() => {
        fetchIngredients();
    }, []);

    useEffect(() => {
        fetchIngredients();
    }, [search]);

    const handleEdit = (categoryId: any) => {
        navigate(`/ingredients/edit/${categoryId}`);
    };

    const handleDelete = async (categoryId: any) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this category?');
        if (confirmDelete) {
            try {
                await fetchAPIResponse(`/ingredients/${categoryId}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                fetchIngredients();
            } catch (error) {
                toast.error((error as Error).message);
            }
        }
    };

  return (
    <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
      <Header title={'Ingredients'} button={true} buttonTitle={'Add ingredients'} action={'/ingredients/add'}/>
      <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
        <Search search={search} setSearch={setSearch} placeholderText={'Ingredient'}/>
        <div className='w-full overflow-visible flex-grow'>
          <div className="w-full overflow-visible">
              <div className='w-full grid grid-cols-4 font-bold text-mainTextColor my-4'>
                <div>Name</div>
                <div>Price</div>
                <div>Actions</div>
              </div>
              <hr className='border-t border-[#cdcdd0]' />
              {ingredients.map((item) => (
                <div key={item.id} className='w-full grid grid-cols-4 my-4'>
                  <div className='text-TextBlue flex items-center'>
                    <p className='underline'>{item.name}</p>
                  </div>
                  <div className='text-TextBlue flex items-center'>
                    <p className=''>${item.base_price}</p>
                  </div>
                  <div className='flex items-center space-x-4'>
                    <button onClick={() => handleEdit(item.id)} className='text-[#1490C2] underline'>Edit</button>
                    <button onClick={() => handleDelete(item.id)} className='text-[#C41E3A]'>Delete</button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ingredients
