import React, {useEffect, useState} from 'react';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import Search from '../../components/Search';
import {CategoryDTO} from "../../dtos/category.dto";
import {useRecoilValue} from "recoil";
import {fetchAPIResponseSelector} from "../../store/user.atom";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";

const Categories = () => {
    const [search, setSearch] = useState('');
    const [categories, setCategories] = useState<CategoryDTO[]>([]);
    const navigate = useNavigate();
    const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

    const fetchCategories = async () => {
        const categories = await fetchAPIResponse<CategoryDTO[]>(`/categories?search=${search}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        });

        setCategories(categories.data);
    }

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [search]);

    const handleEdit = (categoryId: any) => {
        navigate(`/categories/edit/${categoryId}`);
    };

    const handleDelete = async (categoryId: any) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this category?');
        if (confirmDelete) {
            try {
                await fetchAPIResponse(`/categories/${categoryId}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                fetchCategories();
            } catch (error) {
                toast.error((error as Error).message);
            }
        }
    };

    return (
    <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
        <Header title={'Categories'} button={true} buttonTitle={'Add category'} action={'/categories/add'}/>
            <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
            <Search search={search} setSearch={setSearch} placeholderText={'Category'}/>
            <div className='w-full overflow-visible flex-grow'>
              <div className="w-full overflow-visible">
                  <div className='w-full grid grid-cols-3 font-bold text-mainTextColor my-4'>
                      <div>Name</div>
                      <div>Image</div>
                      <div>Actions</div>
                  </div>
                  <hr className='border-t border-[#cdcdd0]' />
                  {categories.map((item) => (
                    <div key={item.id} className='w-full grid grid-cols-3 my-4'>
                      <div className='text-TextBlue flex items-center'>
                          <p className='underline'>{item.name}</p>
                      </div>
                      <div className='flex items-center'>
                          <img src={item.image} alt={item.name} className='w-20 h-20' />
                      </div>
                      <div className='flex items-center space-x-4'>
                        <button onClick={() => handleEdit(item.id)} className='text-[#1490C2] underline'>Edit</button>
                        <button onClick={() => handleDelete(item.id)} className='text-[#C41E3A]'>Delete</button>
                      </div>
                    </div>
                  ))}
              </div>
        </div>
    </div>
    </div>
    );
};

export default Categories;
