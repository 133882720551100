import React, { useState, useEffect, useRef, useCallback } from 'react';
import Header from '../../components/Header';
import Search from '../../components/Search';
import { Link } from 'react-router-dom';
import {useRecoilValue} from "recoil";
import {fetchAPIResponseSelector} from "../../store/user.atom";
import {CategoryDTO} from "../../dtos/category.dto";
import {BaseItemDTO} from "../../dtos/item.dto";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";

type Item = {
    id: string;
    name: string;
    base_price: number;
    image: string;
};

type Props = {};

const Items: React.FC<Props> = () => {
    const navigate = useNavigate();

    const [search, setSearch] = useState<string>('');
    const [items, setItems] = useState<Item[]>([]);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(1);
    const observer = useRef<IntersectionObserver | null>(null);
    const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);
    const [category, setCategory] = useState<string | undefined>(undefined);
    const [categories, setCategories] = useState<CategoryDTO[]>([]);

    useEffect(() => {
        fetchItems(true)
    }, [category, search]);

    useEffect(() => {
        if(page !== 1) {
            fetchItems(false)
        }
    }, [page]);

    const fetchItems = async (reset: boolean = false) => {
        try {
            let fetchUrl = `/items/admin?search=${search}&size=${size}`;

            if(category !== undefined) {
                fetchUrl += `&category=${category}`
            }

            if(reset) {
                fetchUrl += `&page=1`
            } else {
                fetchUrl += `&page=${page}`
            }

            const response = await fetchAPIResponse<{
                pagination_options: any,
                contents: BaseItemDTO[]
            }>(fetchUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            setItems(prevItems => (reset ? response.data.contents : [...prevItems, ...response.data.contents]));
            setTotalPages(response.data.pagination_options.total_pages);

            if(reset) {
                setPage(1)
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const lastItemRef = useCallback((node: HTMLDivElement | null) => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && page < totalPages) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [page, totalPages]);

    const handleEdit = (itemId: any) => {
        navigate(`/items/edit/${itemId}`);
    };

    const handleDelete = async (itemId: any) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this item?');
        if (confirmDelete) {
            try {
                await fetchAPIResponse(`/items/admin/${itemId}`, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                setPage(1)
                setItems([])
                fetchItems(true);
            } catch (error) {
                toast.error((error as Error).message);
            }
        }
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetchAPIResponse<CategoryDTO[]>('/categories', {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                });

                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    return (
        <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
            <Header title={'Items'} button={true} buttonTitle={'Add item'} action={'/items/add'} />
            <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
                <Search search={search} setSearch={setSearch} placeholderText={'Item'}/>
                <div className='my-4'>
                    <select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className='p-2 border rounded'
                    >
                        <option value="">All Categories</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='w-full overflow-visible flex-grow'>
                    <div className="w-full overflow-visible">
                        <div className='w-full grid grid-cols-4 font-bold text-mainTextColor my-4'>
                            <div>Name</div>
                            <div>Price</div>
                            <div>Image</div>
                            <div>Actions</div>
                        </div>
                        <hr className='border-t border-[#cdcdd0]'/>
                        {items.map((item, index) => (
                            <div
                                key={item.id}
                                className='w-full grid grid-cols-4 my-4'
                                ref={index === items.length - 1 ? lastItemRef : null}
                            >
                                <div className='text-TextBlue flex items-center'>
                                    <p className='underline'>{item.name}</p>
                                </div>
                                <div className='text-TextBlue flex items-center'>
                                    <p className=''>${item.base_price}</p>
                                </div>
                                <div className='flex'>
                                    <img src={item.image} alt={item.name} className='w-20 h-20'/>
                                </div>
                                <div className='flex items-center space-x-4'>
                                    <button onClick={() => handleEdit(item.id)}
                                            className='text-[#1490C2] underline'>Edit
                                    </button>
                                    <button onClick={() => handleDelete(item.id)} className='text-[#C41E3A]'>Delete
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Items;
