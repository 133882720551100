import React, {useEffect, useState} from 'react';
import HeaderWithBack from '../../components/HeaderWithBack';
import { useNavigate } from 'react-router';
import {toast} from "react-toastify";
import {useRecoilValue} from "recoil";
import {fetchAPIResponseSelector} from "../../store/user.atom";
import {CategoryDTO} from "../../dtos/category.dto";
import {useParams} from "react-router-dom";

const EditCategory: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

  const [categoryName, setCategoryName] = useState<string>('');
  const [categoryImage, setCategoryImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>('');

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const categoryData = await fetchAPIResponse<CategoryDTO>(`/categories/${id}`, {
          method: "GET",
          headers: {
            "Accept": "application/json"
          }
        });

        setCategoryName(categoryData.data.name);
        setImagePreview(categoryData.data.image);
      } catch (error) {
        toast.error((error as Error).message)
      }
    };

    fetchCategory();
  }, [id]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setCategoryImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleDeleteImage = () => {
    setCategoryImage(null);
    setImagePreview('');
  };

  const handleSaveCategory = async () => {
    if (categoryName === '' || (!categoryImage && !imagePreview)) {
      toast.error("Fill in contents before saving the category");
      return;
    }

    const formData = new FormData();
    formData.set("name", categoryName);

    if (categoryImage) {
      formData.set("image", categoryImage, categoryImage.name);
    }

    try {
      await fetchAPIResponse<CategoryDTO>(`/categories/${id}`, {
        method: "PUT",
        headers: {
          "Accept": "application/json"
        },
        body: formData
      });

      navigate('/categories');
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
      <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
        <HeaderWithBack previousPage={'Categories'} currentPage={'Edit Category'} previousPageUrl={'/categories'} />
        <div className='bg-WhiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
          <div className='flex items-center space-x-4'>
            <label htmlFor='category-image' className='bg-[#1490C2] text-white rounded-md p-2 cursor-pointer'>
              Choose Image
            </label>
            <input
                type='file'
                id='category-image'
                accept='image/*'
                onChange={handleImageChange}
                className='hidden'
            />
            {imagePreview && (
                <div className='flex items-center space-x-4'>
                  <img src={imagePreview} alt='Category' className='w-20 h-20' />
                  <button onClick={handleDeleteImage} className='bg-[#C41E3A] text-white rounded-md p-2'>
                    Delete Image
                  </button>
                </div>
            )}
          </div>
          <input
              type='text'
              placeholder='Category Name'
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md'
          />
          <button onClick={handleSaveCategory} className='bg-[#1490C2] text-white rounded-md p-2 mt-4 w-full'>
            Save Category
          </button>
        </div>
      </div>
  );
};

export default EditCategory;
