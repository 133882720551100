import React, { useEffect, useState } from 'react';
import HeaderWithBack from '../../components/HeaderWithBack';
import { useNavigate, useParams } from 'react-router';
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { fetchAPIResponseSelector } from "../../store/user.atom";
import IngredientSelector, { SelectedIngredient } from "../../components/items/IngredientSelector";
import { CategoryDTO } from "../../dtos/category.dto";
import {EnrichedItemDTO} from "../../dtos/item.dto";

const EditItem: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

    const [itemName, setItemName] = useState<string>('');
    const [itemDescription, setItemDescription] = useState<string>('');
    const [itemImage, setItemImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string>('');
    const [itemPrice, setItemPrice] = useState<number>();
    const [discountAvailable, setDiscountAvailable] = useState<boolean>(false);
    const [discountPercentage, setDiscountPercentage] = useState<number>(0);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [ingredients, setIngredients] = useState<SelectedIngredient[]>([]);
    const [addons, setAddons] = useState<SelectedIngredient[]>([]);
    const [combos, setCombos] = useState<SelectedIngredient[]>([]);
    const [offerAvailable, setOfferAvailable] = useState<boolean>(false);
    const [disabledItem, setDisabledItem] = useState<boolean>(false);
    const [categories, setCategories] = useState<CategoryDTO[]>([]);

    useEffect(() => {
        const fetchItem = async () => {
            try {
                const itemData = await fetchAPIResponse<EnrichedItemDTO>(`/items/${id}`, {
                    method: "GET",
                    headers: {
                        "Accept": "application/json"
                    }
                }, false);

                const item = itemData.data;
                setItemName(item.name);
                setItemDescription(item.description);
                setImagePreview(item.image);
                setItemPrice(item.base_price);
                setDiscountAvailable(item.discount_options.discount);
                setDiscountPercentage(item.discount_options.discount_percentage);
                setSelectedCategory(item.category);
                setIngredients(item.ingredients.map(r => ({
                    ingredient: {
                        value: r.id,
                        label: r.name,
                    },
                    quantity: r.quantity,
                    minQuantity: r.minQuantity,
                    maxQuantity: r.maxQuantity
                })));
                setAddons(item.addons.map(r => ({
                    ingredient: {
                        value: r.id,
                        label: r.name,
                    },
                    quantity: r.quantity,
                    minQuantity: r.minQuantity,
                    maxQuantity: r.maxQuantity
                })));
                setCombos(item.combos.map(r => ({
                    ingredient: {
                        value: r.id,
                        label: r.name,
                    },
                    quantity: r.quantity,
                    minQuantity: r.minQuantity,
                    maxQuantity: r.maxQuantity
                })));
                setOfferAvailable(item.offer);
                setDisabledItem(item.disabled);
            } catch (error) {
                toast.error((error as Error).message);
            }
        };

        fetchItem();
    }, [id, fetchAPIResponse]);

    useEffect(() => {
        const fetchCategories = async () => {
            const categories = await fetchAPIResponse<CategoryDTO[]>(`/categories?search=`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            setCategories(categories.data);
        }

        fetchCategories();
    }, [fetchAPIResponse]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setItemImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleDeleteImage = () => {
        setItemImage(null);
        setImagePreview('');
    };

    const handleSaveItem = async () => {
        try {
            const formData = new FormData();
            formData.set("name", itemName);
            formData.set("description", itemDescription);
            if (itemImage) {
                formData.set("image", itemImage, itemImage.name);
            }
            formData.set("base_price", itemPrice?.toFixed(2)!);
            formData.set("category", selectedCategory!);
            formData.set("discount_options", JSON.stringify({
                discount: discountAvailable,
                discount_percentage: discountPercentage,
            }));
            formData.set("ingredients", JSON.stringify(ingredients.map(r => ({
                ingredient: r.ingredient.value,
                quantity: r.quantity,
                minQuantity: r.minQuantity,
                maxQuantity: r.maxQuantity
            }))));
            formData.set("addons", JSON.stringify(addons.map(r => ({
                ingredient: r.ingredient.value,
                quantity: r.quantity,
                minQuantity: r.minQuantity,
                maxQuantity: r.maxQuantity
            }))));
            formData.set("combos", JSON.stringify(combos.map(r => ({
                ingredient: r.ingredient.value,
                quantity: r.quantity,
                minQuantity: r.minQuantity,
                maxQuantity: r.maxQuantity
            }))));
            formData.set("offer", offerAvailable!.toString())
            formData.set("disabled", disabledItem!.toString());

            await fetchAPIResponse<EnrichedItemDTO>(`/items/admin/${id}`, {
                method: "PUT",
                headers: {
                    "Accept": "application/json"
                },
                body: formData
            });

            navigate('/items');
        } catch (e) {
            toast.error((e as Error).message);
        }
    };

    return (
        <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
            <HeaderWithBack previousPage={'Items'} currentPage={'Edit Item'} previousPageUrl={'/items'} />
            <div className='bg-WhiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
                <input
                    type='text'
                    placeholder='Item Name'
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
                />
                <input
                    type='text'
                    placeholder='Description'
                    value={itemDescription}
                    onChange={(e) => setItemDescription(e.target.value)}
                    className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
                />
                <div className='flex items-center space-x-4 mt-4'>
                    <label htmlFor='item-image' className='bg-blue-500 text-white rounded-md p-2 cursor-pointer'>
                        Choose Image
                    </label>
                    <input type='file' id='item-image' accept='image/*' onChange={handleImageChange} className='hidden' />
                    {imagePreview && (
                        <div className='flex items-center space-x-4'>
                            <img src={imagePreview} alt='Item' className='w-20 h-20' />
                            <button onClick={handleDeleteImage} className='bg-red-500 text-white rounded-md p-2'>
                                Delete Image
                            </button>
                        </div>
                    )}
                </div>
                <input
                    type='number'
                    placeholder='Price'
                    value={itemPrice}
                    onChange={(e) => setItemPrice(parseFloat(e.target.value))}
                    className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
                />
                <div className='mt-4'>
                    <label htmlFor='discount-toggle' className='mr-2'>Discount Available:</label>
                    <input
                        type='checkbox'
                        id='discount-toggle'
                        checked={discountAvailable}
                        onChange={(e) => setDiscountAvailable(e.target.checked)}
                    />
                </div>
                {discountAvailable && (
                    <input
                        type='number'
                        placeholder='Discount Percentage'
                        value={discountPercentage}
                        onChange={(e) => setDiscountPercentage(parseFloat(e.target.value))}
                        className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
                    />
                )}
                <div className='mt-4'>
                    <label htmlFor='category-select' className='mr-2'>Category:</label>
                    <select
                        id='category-select'
                        value={selectedCategory === null ? '' : selectedCategory}
                        onChange={(e) => {
                            setSelectedCategory(e.target.value === '' ? null : e.target.value)
                        }}
                        className='p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md mt-2'
                    >
                        <option value=''>Select a category</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                </div>
                <IngredientSelector type={"Ingredients"} ingredients={ingredients} setIngredients={setIngredients}/>
                <IngredientSelector type={"Addons"} ingredients={addons} setIngredients={setAddons}/>
                <IngredientSelector type={"Combos"} ingredients={combos} setIngredients={setCombos}/>
                <div className='mt-4'>
                    <label htmlFor='discount-toggle' className='mr-2'>Offer:</label>
                    <input
                        type='checkbox'
                        id='discount-toggle'
                        checked={offerAvailable}
                        onChange={(e) => setOfferAvailable(e.target.checked)}
                    />
                </div>
                <div className='mt-4'>
                    <label htmlFor='discount-toggle' className='mr-2'>Disabled:</label>
                    <input
                        type='checkbox'
                        id='discount-toggle'
                        checked={disabledItem}
                        onChange={(e) => setDisabledItem(e.target.checked)}
                    />
                </div>
                <button onClick={handleSaveItem} className='bg-blue-500 text-white rounded-md p-2 mt-4 w-full'>
                    Save Item
                </button>
            </div>
        </div>
    );
};

export default EditItem;