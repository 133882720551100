import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './screens/Login';
import Navbar from './components/Navbar';
import {useEffect, useState} from 'react';
import Analytics from './screens/NavBar/Analytics';
import Categories from './screens/NavBar/Categories';
import Ingredients from './screens/NavBar/Ingredients';
import Items from './screens/NavBar/Items';
import Orders from './screens/NavBar/Orders';
import Settings from './screens/NavBar/Settings';
import AddCategory from './screens/Categories/AddCategory';
import OrderDetails from './screens/Orders/OrderDetails';
import {useRecoilState} from "recoil";
import {adminState} from "./store/user.atom";
import {AsyncLocalStorage} from "async_hooks";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditCategory from "./screens/Categories/EditCategory";
import AddIngredient from "./screens/Ingredients/AddIngredient";
import AddItem from "./screens/Items/AddItem";
import EditIngredient from "./screens/Ingredients/EditIngredient";
import EditItem from "./screens/Items/EditItem";
import Notifications from './screens/NavBar/Notifications';

function App() {

  const [admin, setAdmin] = useRecoilState(adminState);

  return (
    <BrowserRouter>
      <div className='flex font-font400'>
        {
          admin ?
          <>
            <Navbar />
            <div className="h-screen w-screen bg-SecondMainBgColor">
              <div className={`h-screen w-full pl-72 flex flex-col`}>
                <Routes>
                  <Route path="/" element={<Analytics />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/categories" element={<Categories />} />
                  <Route path="/categories/add" element={<AddCategory />} />
                  <Route path="/categories/edit/:id" element={<EditCategory />} />
                  <Route path="/ingredients" element={<Ingredients />} />
                  <Route path="/ingredients/add" element={<AddIngredient />} />
                  <Route path="/ingredients/edit/:id" element={<EditIngredient />} />
                  <Route path="/items" element={<Items />} />
                  <Route path="/items/add" element={<AddItem />} />
                  <Route path="/items/edit/:id" element={<EditItem />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/orders/view/:id" element={<OrderDetails />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/notifications" element={<Notifications />} />
                </Routes>
              </div>
            </div>
          </> :
          <Login />
        }
      </div>
      <ToastContainer/>
    </BrowserRouter>
  );
}

export default App;
