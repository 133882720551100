import React, { useState } from 'react'
import {useRecoilState, useRecoilValue} from "recoil";
import {adminState, fetchAPIResponseSelector} from "../store/user.atom";
import {toast} from "react-toastify";
import {AdminDTO} from "../dtos/admin.dto";

type Props = {}

const Login = (props: Props) => {
    const [admin, setAdmin] = useRecoilState(adminState);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!username || !password) {
        toast.error('Please enter both email and password');
        return;
      }

      try {
          const admin = await fetchAPIResponse<AdminDTO>("/admins/login", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json"
              },
              body: JSON.stringify({
                  email: username,
                  password
              })
          });

          setAdmin(admin.data);
          localStorage.setItem("zburgerhouse_admin", JSON.stringify(admin.data)) //todo move this to a selector
      } catch (e) {
          toast.error((e as Error).message)
      } finally {

      }
    };

    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 w-full">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-bold text-mainTextColor font-poppins">Login</h2>
          </div>
          {error && <div className="text-red-500 text-center">{error}</div>}
          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-mainTextColor">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-mainTextColor">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-primary rounded-md"
              />
            </div>
            <div>
              <button
                type="submit"
                className="mt-4 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-primary  focus:outline-none hover:bg-primaryHover"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    );
}

export default Login
