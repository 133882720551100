import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

type Props = {}

const Navbar = (props: Props) => {

  const navigate = useNavigate();

  useEffect(() => {
    navigate('/analytics');
  }, []);

  return (
    <div className={`h-screen fixed z-10 bg-navbarColor top-0 left-0 py-4 pr-1 m-0 flex flex-col justify-between shadow-sm w-72`}>
      <div>
        <ul>
          <li className={`flex mb-8`}>
            <button
              className='text-white px-3 py-3 pl-16 min-w-full w-3/4 flex items-center text-lg'
            >
              <p className='font-medium font-poppins'>zBurgerHouse Console</p>
            </button>
          </li>
          <li className={`mb-1`}>
            <NavLink
              to="/analytics"
              className={({ isActive }) =>
                isActive
                  ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                  : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
              }
            >
              <p className='font-poppins'>Analytics</p>
            </NavLink>
          </li>
          <li className={`mb-1`}>
              <NavLink
                  to="/categories"
                  className={({ isActive }) =>
                      isActive
                          ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                          : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
                  }
              >   
                <p className='font-poppins'>Categories</p>
              </NavLink>
          </li>

          <li className={`mb-1`}>
              <NavLink
                  to="/ingredients"
                  className={({ isActive }) =>
                      isActive
                          ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                          : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
                  }
              >   
                <p className='font-poppins'>Ingredients</p>
              </NavLink>
          </li>
          <li className={`mb-1`}>
              <NavLink
                  to="/items"
                  className={({ isActive }) =>
                      isActive
                          ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                          : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
                  }
              >   
                <p className='font-poppins'>Items</p>
              </NavLink>
          </li>
          <li className={`mb-1`}>
              <NavLink
                  to="/orders"
                  className={({ isActive }) =>
                      isActive
                          ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                          : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
                  }
              >   
                <p className='font-poppins'>Orders</p>
              </NavLink>
          </li>
          <li className={`mb-1`}>
              <NavLink
                  to="/settings"
                  className={({ isActive }) =>
                      isActive
                          ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                          : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
                  }
              >   
                <p className='font-poppins'>Settings</p>
              </NavLink>
          </li>
          <li className={`mb-1`}>
              <NavLink
                  to="/notifications"
                  className={({ isActive }) =>
                      isActive
                          ? `text-white py-3 px-3 pl-16 bg-SelectedBarText w-3/4 flex rounded-r-full items-center`
                          : `text-white px-3 py-3 pl-16 w-3/4 flex items-center`
                  }
              >   
                <p className='font-poppins'>Notifications</p>
              </NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar