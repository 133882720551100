import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useRecoilValue } from "recoil";
import { fetchAPIResponseSelector } from "../../store/user.atom";
import { IngredientDTO } from "../../dtos/ingredient.dto";
import {toast} from "react-toastify";

export interface Ingredient {
    id: string;
    name: string;
}

export interface IngredientOption {
    value: string;
    label: string;
}

export interface SelectedIngredient {
    ingredient: IngredientOption;
    quantity: number;
    minQuantity: number;
    maxQuantity: number;
}

export interface IngredientSelectorProp {
    type: string;
    ingredients: SelectedIngredient[];
    setIngredients: React.Dispatch<React.SetStateAction<SelectedIngredient[]>>;
}

const IngredientSelector: React.FC<IngredientSelectorProp> = ({ type, ingredients, setIngredients }) => {
    const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

    const fetchIngredients = async (inputValue: string): Promise<IngredientOption[]> => {
        try {
            const ingredients = await fetchAPIResponse<IngredientDTO[]>(`/ingredients?search=${inputValue}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
            });

            return ingredients.data.map((ingredient: Ingredient) => ({
                value: ingredient.id,
                label: ingredient.name
            }));
        } catch (error) {
            console.error('Error fetching ingredients:', error);
            return [];
        }
    };

    const handleIngredientSelect = (index: number, selectedOption: IngredientOption | null) => {
        if (selectedOption) {
            const updatedIngredients = [...ingredients];
            updatedIngredients[index].ingredient = selectedOption;
            setIngredients(updatedIngredients);
        }
    };

    const handleIngredientQuantityChange = (index: number, field: 'quantity' | 'minQuantity' | 'maxQuantity', value: number) => {
        const updatedIngredients = [...ingredients];
        if (field === 'quantity') {
            if (value >= updatedIngredients[index].minQuantity && value <= updatedIngredients[index].maxQuantity) {
                updatedIngredients[index][field] = value;
            } else {
                toast.error('Quantity must be between min and max quantities');
                return;
            }
        } else if (field === 'minQuantity') {
            if (value <= updatedIngredients[index].maxQuantity) {
                updatedIngredients[index][field] = value;
                if (updatedIngredients[index].quantity < value) {
                    updatedIngredients[index].quantity = value;
                }
            } else {
                toast.error('Min quantity cannot be greater than max quantity');
                return;
            }
        } else if (field === 'maxQuantity') {
            if (value >= updatedIngredients[index].minQuantity) {
                updatedIngredients[index][field] = value;
                if (updatedIngredients[index].quantity > value) {
                    updatedIngredients[index].quantity = value;
                }
            } else {
                toast.error('Max quantity cannot be less than min quantity');
                return;
            }
        }
        setIngredients(updatedIngredients);
    };

    const handleAddMore = () => {
        setIngredients(prevState => [...prevState, { ingredient: { value: '', label: '' }, quantity: 0, minQuantity: 0, maxQuantity: 0 }]);
    };

    const handleDelete = (index: number) => {
        setIngredients(prevState => prevState.filter((_, i) => i !== index));
    };

    return (
        <div className={"my-8"}>
            <label htmlFor='category-select' className='mr-2'>{type}:</label>
            <div className='mt-4 p-2 border border-gray-300 rounded-md max-h-40 overflow-y-auto'>
                <div className="flex items-center justify-between mb-2">
                    <span className="mr-2 w-1/2"></span>
                    <span className="mr-2 w-1/6">Quantity</span>
                    <span className="mr-2 w-1/6">Min Quantity</span>
                    <span className="mr-2 w-1/6">Max Quantity</span>
                </div>
                {ingredients.map((item, index) => (
                    <div key={index} className="flex items-center justify-between mb-2">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchIngredients}
                            defaultOptions
                            value={item.ingredient}
                            onChange={(selectedOption) => handleIngredientSelect(index, selectedOption)}
                            className="mr-2 w-1/4"
                            placeholder="Select Ingredient"
                            styles={{
                                menuPortal: (base) => ({...base, zIndex: 9999}),
                                container: (base) => ({...base, flex: 1}),
                            }}
                            menuPortalTarget={document.body}
                        />
                        <input
                            type="number"
                            placeholder="Quantity"
                            value={item.quantity}
                            onChange={(e) => handleIngredientQuantityChange(index, 'quantity', parseFloat(e.target.value))}
                            className="p-2 border border-gray-300 rounded-md mr-2 w-1/6"
                        />
                        <input
                            type="number"
                            placeholder="Min Quantity"
                            value={item.minQuantity}
                            onChange={(e) => handleIngredientQuantityChange(index, 'minQuantity', parseFloat(e.target.value))}
                            className="p-2 border border-gray-300 rounded-md mr-2 w-1/6"
                        />
                        <input
                            type="number"
                            placeholder="Max Quantity"
                            value={item.maxQuantity}
                            onChange={(e) => handleIngredientQuantityChange(index, 'maxQuantity', parseFloat(e.target.value))}
                            className="p-2 border border-gray-300 rounded-md mr-2 w-1/6"
                        />
                        <button
                            onClick={() => handleDelete(index)}
                            className="p-2 bg-red-500 text-white rounded-md"
                        >
                            Delete
                        </button>
                    </div>
                ))}
            </div>
            <button onClick={handleAddMore} className="mt-4 p-2 bg-blue-500 text-white rounded-md">Add More</button>
        </div>
    );
};

export default IngredientSelector;
