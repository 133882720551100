import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as CrossIcon } from '../images/VectorCreateCross.svg';

type Props = {
    title: String,
    button: boolean,
    buttonTitle: String,
    action: string,
}

function Header({title, button, buttonTitle, action}: Props) {
  return (
    <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md flex items-center pl-12 justify-between pr-12 py-4 h-20">
    <div className='flex items-center'>
        <p className='font-bold text-lg text-mainTextColor mr-5'>{title}</p>
    </div>
    {
        button && (
            <div className='flex items-center'>
                <Link to={action} className="relative">
                    <button
                    className="border flex items-center bg-[#1490C2] text-white px-8 py-2 rounded-full focus:outline-none active:bg-[#0f7fb6] font-semibold"
                    >
                        <CrossIcon className="mr-2" />
                        {buttonTitle}
                    </button>
                </Link>
            </div>
        )
    }
    </div>
  )
}

export default Header