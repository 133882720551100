import {atom, selector} from "recoil";
import {AdminDTO} from "../dtos/admin.dto";
import {API_URL} from "../utils/constants";
import {ServerResponse} from "../utils/response";

export const adminState = atom({
    key: 'admin',
    default: (() => {
        const admin = localStorage.getItem("zburgerhouse_admin");

        if (admin) {
            return JSON.parse(admin) as AdminDTO;
        }

        return null;
    })()
});

export const fetchAPIResponseSelector = selector({
    key: 'fetchAPIResponseSelector',
    get: ({get}) => {
        const admin = get(adminState);

        return async <T>(path: string, options: RequestInit, forceAuthorization = true) => {
            const headers = options.headers || {} as any;

            if (admin && admin.token && forceAuthorization) {
                headers['Authorization'] = `${admin.token}`;
            }

            const response = await fetch(API_URL + path, {
                ...options,
                headers
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                if (errorResponse.code && errorResponse.message) {
                    throw new Error(`${errorResponse.code}: ${errorResponse.message}`);
                } else {
                    throw new Error("An unexpected error occurred");
                }
            }

            return await response.json() as ServerResponse<T>;
        }
    },
});
