import React, {useEffect, useRef, useState} from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import HeaderWithBack from '../../components/HeaderWithBack';
import {useNavigate, useParams} from "react-router";
import {EnrichedItemDTO} from "../../dtos/item.dto";
import {toast} from "react-toastify";
import {useRecoilValue} from "recoil";
import {fetchAPIResponseSelector} from "../../store/user.atom";
import {OrderDTO, OrderState} from "../../dtos/order.dto";
import CustomerPrintableOrder from "./CustomerPrintableOrder";

const OrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

  const [order, setOrder] = useState<OrderDTO | undefined>(undefined);
  const printRef = useRef(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderDTOServerResponse = await fetchAPIResponse<OrderDTO>(`/orders/${id}`, {
          method: "GET",
          headers: {
            "Accept": "application/json"
          }
        });

        setOrder(orderDTOServerResponse.data)
      } catch (error) {
        toast.error((error as Error).message);
      }
    };

    fetchOrder();
  }, [id, fetchAPIResponse]);

  const handleStateChange = async (newState: OrderState) => {
    try {
      if(order) {
        setOrder({
          ...order,
          state: newState
        })
      }

      await fetchAPIResponse(`/orders/${order?.id}/status`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          toState: newState
        })
      });

    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  // Define styles
  const titleStyle = {
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '4px',
  };

  const detailStyle = {
    marginBottom: '16px',
    backgroundColor: 'white',
    padding: 10,
    borderRadius: '10px',
  };

  const mapContainerStyle = {
    height: '200px',
    width: '100%',
    marginTop: '20px',
  };

  const labelStyle = {
    color: '#666',
    marginRight: '10px',
    marginLeft: '10px'
  };

  const valueStyle = {
    color: '#000',
  };

  const handlePrint = () => {
    const printContent = document.getElementById('printableOrder');
    const printWindow = window.open('', '', 'height=600,width=800');

    if(printWindow) {
      printWindow.document.write('<html><head><title>Print</title>');
      printWindow.document.write('</head><body >');
      printWindow.document.write(printContent!.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();

      if(order?.state === OrderState.PENDING) {
        handleStateChange(OrderState.PREPARING);
      }
    }
  };


  return (
    <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
      <HeaderWithBack previousPage={'Orders'} currentPage={'Order Details'} previousPageUrl={'/orders'} />
      <div className='bg-whiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
        {order &&
            <>
              <CustomerPrintableOrder ref={printRef} order={order} />
              <div style={detailStyle}>
                {order.items.map((item, index) => (
                    <div key={item.id} style={detailStyle}>
                      <div style={{...detailStyle, display: 'flex', alignItems: 'center'}}>
                        <img src={item.image} alt={item.name}
                             style={{width: '60px', height: '60px', borderRadius: '8px'}}/>
                        <div>
                          <span style={labelStyle}>{item.quantity} x {item.name}</span>
                          <span style={valueStyle}>${item.price}</span>
                          <p style={labelStyle}>Modifications:</p>
                          {item.contents.length > 0 && (
                              <ul style={{marginLeft: '10px'}}>
                                {item.contents.map((content, index) => (
                                    <p key={index} style={{...valueStyle}}>{content}</p>
                                ))}
                              </ul>
                          )}
                          <p style={labelStyle}>Note: {item.note}</p>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
              <div style={detailStyle}><span style={labelStyle}>Subtotal:</span> <span
                  style={valueStyle}>${order.subtotal}</span></div>
              <div style={detailStyle}><span style={labelStyle}>Delivery:</span> <span
                  style={valueStyle}>${order.delivery}</span></div>
              <div style={detailStyle}><span style={labelStyle}>Total:</span> <span
                  style={valueStyle}>${order.total}</span></div>
              <div style={detailStyle}><span style={labelStyle}>Address:</span> <span
                  style={valueStyle}>{`${order.address.building}, ${order.address.street}, Apartment ${order.address.apartment}`}</span>
              </div>
              <div style={detailStyle}><span style={labelStyle}>Additional Information:</span> <span
                  style={valueStyle}>{order.address.additional_information}</span></div>
              <div style={detailStyle} className='flex items-center'>
                <span style={labelStyle}>State:</span>
                <div className='text-TextBlue flex items-center'>
                  <select
                      value={order.state}
                      onChange={(e) => handleStateChange(e.target.value as OrderState)}
                      className="rounded-md border border-gray-300 p-1"
                  >
                    <option value="PENDING">Pending</option>
                    <option value="PREPARING">Preparing</option>
                    <option value="DELIVERING">Delivering</option>
                    <option value="DELIVERED">Delivered</option>
                    <option value="CANCELLED">Cancelled</option>
                  </select>
                </div>
              </div>
            </>
        }
        {/*<div style={mapContainerStyle}>
          <MapContainer center={centerPosition} zoom={13} scrollWheelZoom={false} style={{ height: '100%', width: '100%' }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            <Marker position={[address.latitude, address.longitude]}>
              <Popup>Delivery Address: {address.building}, {address.street}</Popup>
            </Marker>
          </MapContainer>
        </div>*/}
        <div className='flex items-center justify-end mt-5'>
          <button
              className="border flex items-center bg-[#1490C2] text-white px-8 py-2 rounded-full focus:outline-none active:bg-[#0f7fb6] font-semibold"
              onClick={handlePrint}
          >
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
