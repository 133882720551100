import React, {useCallback, useEffect, useRef, useState} from 'react'
import Header from '../../components/Header'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {Link} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {adminState, fetchAPIResponseSelector} from "../../store/user.atom";
import {OrderDTO, OrderState} from "../../dtos/order.dto";
import {toast} from "react-toastify";
import {API_URL} from "../../utils/constants";

type Props = {}

const Analytics = (props: Props) => {

  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);
  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 999);

  const [startDate, setStartDate] = useState<Date | undefined>(startOfDay);
  const [endDate, setEndDate] = useState<Date | undefined>(endOfDay);

  const [income, setIncome] = useState(0);
  const [ordersDelivered, setOrdersDelivered] = useState(-0);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const observer = useRef<IntersectionObserver | null>(null);
  const [admin, setAdmin] = useRecoilState(adminState);
  const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);
  const [orders, setOrders] = useState<OrderDTO[]>([]);
  const [orderState, setOrderState] = useState(OrderState.DELIVERED);

  const lastItemRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [page, totalPages]);

  useEffect(() => {
    fetchOrders(true)
  }, [orderState]);

  useEffect(() => {
    fetchOrders(true)
    fetchAnalytics()
  }, [startDate, endDate]);

  useEffect(() => {
    if(page !== 1) {
      fetchOrders(false)
    }
  }, [page]);

  const fetchAnalytics = async () => {
    try {
      let fetchUrl = `/admins/analytics/?from=${startDate}&to=${endDate}`;

      const response = await fetchAPIResponse<{income: number, orders_delivered: number}>(fetchUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
      });


      setIncome(response.data.income);
      setOrdersDelivered(response.data.orders_delivered);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const fetchOrders = async (reset: boolean = false) => {
    try {
      let fetchUrl = `/orders/?size=${size}&from=${startDate}&to=${endDate}`;

      if(orderState !== undefined) {
        fetchUrl += `&state=${orderState}`
      }

      if(reset) {
        fetchUrl += `&page=1`
      } else {
        fetchUrl += `&page=${page}`
      }

      const response = await fetchAPIResponse<{
        pagination_options: any,
        contents: OrderDTO[]
      }>(fetchUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
      });

      setOrders(prevItems => (reset ? response.data.contents : [...prevItems, ...response.data.contents]));
      setTotalPages(response.data.pagination_options.total_pages);

      if(reset) {
        setPage(1)
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const fetchUserReport = async () => {
    await toast.promise(
        fetch(API_URL + "/admins/users/csv", {
          method: "GET",
          headers: {
            "Accept": "text/csv",
            "Authorization": admin!.token
          }
        }).then(response => response.blob()), // Fetch the response as a Blob
        {
          pending: "Fetching user CSVs...",
          error: "Could not fetch user CSVs",
          success: "Successfully fetched user CSVs"
        }
    ).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_report.csv'; // Set the desired file name
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    }).catch(error => {
      console.error("Error fetching user CSVs:", error);
    });
  }

  return (
    <div className='flex flex-col items-center w-full h-full overflow-auto py-10'>
      <Header title={'Analytics'} button={false} buttonTitle={''} action={''} />
      <div className="w-11/12 rounded-2xl bg-WhiteBg shadow-md pl-12 justify-between pr-12 mt-6 font-poppins">
        <div className='w-full flex items-center justify-between'>
          <div className='mt-5 flex flex-col items-center'>
            <button className={"py-2 px-5 text-white bg-green-500 rounded-full text-sm"} onClick={() => {
              fetchUserReport();
            }}>Download User Report</button>
          </div>
        </div>
        <div className="mt-8 mb-8">
          <div className='w-full flex items-center justify-between'>
            <div className='flex flex-col items-center'>
              <label htmlFor="startDate">From Date</label>
              <DatePicker
                  id="startDate"
                  selected={startDate}
                  onChange={date => {
                    if (date != null) setStartDate(date)
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  isClearable
                  placeholderText="Select a start date"
                  className="p-2 rounded-md border border-gray-300"
              />
            </div>
            <div className='flex flex-col items-center'>
              <label htmlFor="endDate">To Date</label>
              <DatePicker
                  id="endDate"
                  selected={endDate}
                  onChange={date => {
                    if (date != null) setEndDate(date)
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  isClearable
                  placeholderText="Select an end date"
                  className="p-2 rounded-md border border-gray-300"
              />
            </div>
          </div>
          <div className='w-full flex items-center mt-8'>
            <label htmlFor="income">Income: ${income}</label>
            <label htmlFor="ordersDelivered" className='ml-10'>Orders Delivered: {ordersDelivered}</label>
          </div>
          <div className='w-full overflow-visible flex-grow mt-8'>
            <div className="w-full overflow-visible">
              <div className='w-full grid grid-cols-7 font-bold text-mainTextColor my-4'>
                <div>Order Date</div>
                <div className={"ml-14"}>Order State</div>
                <div>User</div>
                <div>Phone Number</div>
                <div>Order Total</div>
                <div>Actions</div>
              </div>
              <hr className='border-t border-[#cdcdd0]'/>
              {orders.map((order, index) => (
                  <div
                      key={order.id}
                      className='w-full grid grid-cols-7 my-4'
                      ref={index === orders.length - 1 ? lastItemRef : null}
                  >
                    <div className='w-96 text-TextBlue flex items-center'>
                      <p className='underline'>{new Date(order.order_date).toLocaleString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit'
                      })}
                      </p>
                    </div>
                    <div className='ml-14 text-TextBlue flex items-center'>
                      <select
                          value={order.state}
                          className="rounded-md border border-gray-300 p-1"
                          disabled={true}
                      >
                        <option value="PENDING">Pending</option>
                        <option value="PREPARING">Preparing</option>
                        <option value="DELIVERING">Delivering</option>
                        <option value="DELIVERED">Delivered</option>
                        <option value="CANCELLED">Cancelled</option>
                      </select>
                    </div>
                    <div className='text-TextBlue flex items-center'>
                      <p className=''>{order.user.name}</p>
                    </div>
                    <div className='flex items-center'>
                      <p className=''>{order.user.phone_number}</p>
                    </div>
                    <div className='flex items-center'>
                      <p className=''>${order.total}</p>
                    </div>
                    <div className='flex items-center space-x-4'>
                      <Link to={`/orders/view/${order.id}`} className='text-[#1490C2] underline'>View Details</Link>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analytics
