import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import HeaderWithBack from '../../components/HeaderWithBack';
import IngredientSelector, {SelectedIngredient} from "../../components/items/IngredientSelector";
import ingredientSelector from "../../components/items/IngredientSelector";
import {CategoryDTO} from "../../dtos/category.dto";
import {useRecoilValue} from "recoil";
import {fetchAPIResponseSelector} from "../../store/user.atom";
import {IngredientDTO} from "../../dtos/ingredient.dto";
import {toast} from "react-toastify";
import {EnrichedItemDTO} from "../../dtos/item.dto";
import {useNavigate} from "react-router";
import {objectToFormData} from "../../utils/formdata";

const AddItem: React.FC = () => {

  const navigate = useNavigate();

  const [itemName, setItemName] = useState<string>('');
  const [itemDescription, setItemDescription] = useState<string>('');
  const [itemImage, setItemImage] = useState<File | null>(null);
  const [itemPrice, setItemPrice] = useState<number>();
  const [discountAvailable, setDiscountAvailable] = useState<boolean>(false);
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [ingredients, setIngredients] = useState<SelectedIngredient[]>([]);
  const [addons, setAddons] = useState<SelectedIngredient[]>([]);
  const [combos, setCombos] = useState<SelectedIngredient[]>([]);
  const [offerAvailable, setOfferAvailable] = useState<boolean>(false);
  const [disabledItem, setDisabledItem] = useState<boolean>(false);
  const [categories, setCategories] = useState<CategoryDTO[]>([]);

  const fetchAPIResponse = useRecoilValue(fetchAPIResponseSelector);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setItemImage(file);
    }
  };

  const handleDeleteImage = () => {
    setItemImage(null);
  };

  const handleAddItem = async () => {
    try {
      const formData = new FormData();
      formData.set("name", itemName);
      formData.set("description", itemDescription);
      formData.set("image", itemImage!, itemImage!.name);
      formData.set("base_price", itemPrice?.toFixed(2)!);
      formData.set("category", selectedCategory!);
      formData.set("discount_options", JSON.stringify({
        discount: discountAvailable,
        discount_percentage: discountPercentage,
      }));
      formData.set("ingredients", JSON.stringify(ingredients.map(r => ({
        ingredient: r.ingredient.value,
        quantity: r.quantity,
        minQuantity: r.minQuantity,
        maxQuantity: r.maxQuantity,
      }))));
      formData.set("addons", JSON.stringify(addons.map(r => ({
        ingredient: r.ingredient.value,
        quantity: r.quantity,
        minQuantity: r.minQuantity,
        maxQuantity: r.maxQuantity,
      }))));
      formData.set("combos", JSON.stringify(combos.map(r => ({
        ingredient: r.ingredient.value,
        quantity: r.quantity,
        minQuantity: r.minQuantity,
        maxQuantity: r.maxQuantity,
      }))));
      formData.set("offer", offerAvailable!.toString())
      formData.set("disabled", disabledItem!.toString());

      await fetchAPIResponse<EnrichedItemDTO>("/items/admin/create", {
        method: "POST",
        headers: {
          "Accept": "application/json"
        },
        body: formData
      });

      navigate('/items')
    } catch (e) {
      toast.error((e as Error).message)
    }

    console.log('Item added:', {
      itemName,
      itemDescription,
      itemImage,
      itemPrice,
      discountAvailable,
      discountPercentage,
      selectedCategory,
      ingredients,
      addons,
      combos,
      offerAvailable,
      disabledItem,
    });
  };

  const fetchCategories = async () => {
    const categories = await fetchAPIResponse<CategoryDTO[]>(`/categories?search=`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      },
    });

    setCategories(categories.data);
  }

  useEffect(() => {
    fetchCategories();
  }, []);


  return (
    <div className='flex flex-col w-full h-full overflow-auto items-center py-10'>
      <HeaderWithBack previousPage={'Items'} currentPage={'Add Item'} previousPageUrl={'/items'} />
      <div className='bg-WhiteBg shadow-md w-11/12 px-12 py-6 mt-6 rounded-2xl'>
        {/* Item Name Input */}
        <input
          type='text'
          placeholder='Item Name'
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
        />
        {/* Description Input */}
        <input
          type='text'
          placeholder='Description'
          value={itemDescription}
          onChange={(e) => setItemDescription(e.target.value)}
          className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
        />
        {/* Image Upload */}
        <div className='flex items-center space-x-4 mt-4'>
          <label htmlFor='item-image' className='bg-blue-500 text-white rounded-md p-2 cursor-pointer'>
            Choose Image
          </label>
          <input type='file' id='item-image' accept='image/*' onChange={handleImageChange} className='hidden' />
          {itemImage && (
            <div className='flex items-center space-x-4'>
              <img src={URL.createObjectURL(itemImage)} alt='Item' className='w-20 h-20' />
              <button onClick={handleDeleteImage} className='bg-red-500 text-white rounded-md p-2'>
                Delete Image
              </button>
            </div>
          )}
        </div>
        {/* Price Input */}
        <input
          type='number'
          placeholder='Price'
          value={itemPrice}
          onChange={(e) => setItemPrice(parseFloat(e.target.value))}
          className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
        />
        {/* Discount Toggle */}
        <div className='mt-4'>
          <label htmlFor='discount-toggle' className='mr-2'>Discount Available:</label>
          <input
            type='checkbox'
            id='discount-toggle'
            checked={discountAvailable}
            onChange={(e) => setDiscountAvailable(e.target.checked)}
          />
        </div>
        {/* Discount Percentage Input */}
        {discountAvailable && (
          <input
            type='number'
            placeholder='Discount Percentage'
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(parseFloat(e.target.value))}
            className='mt-4 p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md'
          />
        )}
        {/* Category Selection */}
        <div className='mt-4'>
          <label htmlFor='category-select' className='mr-2'>Category:</label>
          <select
            id='category-select'
            value={selectedCategory === null ? '' : selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value === '' ? null : e.target.value)
            }}
            className='p-2 block w-full shadow-sm focus:ring-primary focus:border-transparent border-gray-300 rounded-md mt-2'
          >
            <option value=''>Select a category</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
        </div>
        <IngredientSelector type={"Ingredients"} ingredients={ingredients} setIngredients={setIngredients}/>
        {/* Addons Selection */}
        <IngredientSelector type={"Addons"} ingredients={addons} setIngredients={setAddons}/>
        {/* Combos Selection */}
        <IngredientSelector type={"Combos"} ingredients={combos} setIngredients={setCombos}/>
        <div className='mt-4'>
          <label htmlFor='discount-toggle' className='mr-2'>Offer:</label>
          <input
            type='checkbox'
            id='discount-toggle'
            checked={offerAvailable}
            onChange={(e) => setOfferAvailable(e.target.checked)}
          />
        </div>
        <div className='mt-4'>
          <label htmlFor='discount-toggle' className='mr-2'>Disabled:</label>
          <input
            type='checkbox'
            id='discount-toggle'
            checked={disabledItem}
            onChange={(e) => setDisabledItem(e.target.checked)}
          />
        </div>
        {/* Add Item Button */}
        <button onClick={handleAddItem} className='bg-blue-500 text-white rounded-md p-2 mt-4 w-full'>
          Add Item
        </button>
      </div>
    </div>
  );
};

export default AddItem;
